.customer-portal .header {
  /* Put a little space in between the icon and the text */
  padding-top: 1em;
}

.customer-portal .icon {
  color: #003A6F;
}


/*.customer-portal i,
.customer-portal i::after,
.customer-portal i::before {
  font-size: 84px;
}*/

.image-instead-of-icon-wrapper {
  /* This is the height of "massive" icons, so without this, the labels in
  cards with <Image>s won't be aligned with the labels in cards with <Icon>s*/
  /*height: 56px;*/
  height: 112px;

  /* Centers contents vertically */
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

.image-instead-of-icon-wrapper img {
  /* Without max-width and max-height values, images will overflow their
   containing div. However, we use width and height here rather than max-width
   and max-height because IE11 doesn't recognize max-width and max-height and
   the images will still overflow their containing div.*/

  /* Images turn out to be a little too big when the image is 100% of the
  containing div's width, so we give it a little left/right padding with a
  max-width value lower than 100% */
  width: 85% !important;
  height: 100% !important;
}


/* Some cards are disabled when the customer is on a credit hold/inactive */
.disabled-card .icon {
  color: #D6D6D6;
}

/* Some cards are disabled when the customer is on a credit hold/inactive */
.disabled-card-label-text {
  color: #C4C4C4!important;
}
