/*
 * *****************************************************************************
 * general
 * *****************************************************************************
*/
body {
  background-color: #eaeaea;
}

.ui.horizontal.divider {
  color: #9c9c9c;
}

.ui.container {
  background-color: white;
  border: 1px solid #cdcdcd;
  margin-bottom: 10px;
}

.no-background-container {
  background-color: transparent !important;
  border: 0 !important;
}

/* Mimics Semantic UI's styling of <small> tags */
.small-text {
  font-size: 80%;
}

.ui.five.cards{
  margin-bottom: 10px;
}

/**
 * This is the easist way to increase the width of Semantic UI React's
 * <Container> component: https://stackoverflow.com/a/40997339. The standard
 * width of a >=1200px container is 1127px; we increase this to 1183px for
 * 1200-1279px screens and 1263px for >=1280px screens (max width minus a
 * 17px-wide vertical scroll bar).
 */
@media only screen and (min-width: 1200px) and (min-width: 1279px) {
  .ui.container {
    width: 1183px;
  }

  .ui.grid.container {
    width: calc( 1183px  +  2rem ) !important;
  }

  .ui.relaxed.grid.container {
    width: calc( 1183px  +  3rem ) !important;
  }

  .ui.very.relaxed.grid.container {
    width: calc( 1183px  +  5rem ) !important;
  }
}

@media only screen and (min-width: 1280px) {
  .ui.container {
    width: 1263px;
  }

  .ui.grid.container {
    width: calc( 1263px  +  2rem ) !important;
  }

  .ui.relaxed.grid.container {
    width: calc( 1263px  +  3rem ) !important;
  }

  .ui.very.relaxed.grid.container {
    width: calc( 1263px  +  5rem ) !important;
  }
}

/**
 * Oftentimes we'll have text that we want to display as a link but we don't
 * actually want the click to route us anywhere, we just want it to render a
 * modal or a popup. This styling mimics that of an <a> tag as styled by
 * Semantic UI React by default.
 */
.link-text {
  color: '#1e70bf'!important;
  cursor: pointer;
}

/**
 * For whatever reason, the textAlign property doesn't work on a bunch of
 * Semantic UI React components (Card, for instance). The corresponding CSS rule
 * provides the desired functionality. Don't use this on Card.Content or
 * Card.Header, just on Card.
 */
.centered-content {
  text-align: center;
}

/**
 * Sometimes setting style={{fontWeight: 'bold'}} doesn't work because it needs
 * an !important. This class provides that.
 */
.bold-text {
  font-weight: bold!important;
}

.microstar-blue {
  color: #003A6F;
}

/* 'fill' is necessary for things such as  SVG files*/
.microstar-blue-fill {
  fill: #003A6F;
}

/**
 * For Semantic UI React's <Button> component. This can be used in conjunction
 * with size="[str]" prop, which only affects the font size of button text.
 */
.reduced-padding-button {
  padding: 0.5em 1em!important;
}


/*
For use on disabled <Form> fields you don't want them to look any lighter than
when not disabled
 */
.no-lighter-on-disabled input:disabled,
.no-lighter-on-disabled label:disabled {
  opacity: unset!important;
}


/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.semantic-ui-error-border {
  border-color: #e0b4b4!important;
}

/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.semantic-ui-error-background-color {
  background: #fff6f6!important;
}

/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.semantic-ui-error-text-color {
  color: #9f3a38!important;
}

/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.input-error-semantic-ui {
  border-color: #e0b4b4!important;
  background: #fff6f6!important;
  color: #9f3a38!important;
}

/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.input-error-semantic-ui-container > * {
  border-color: #e0b4b4!important;
  background: #fff6f6!important;
  color: #9f3a38!important;
}

/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.input-orange-semantic-ui {
  border-color: #f2ae1c!important;
  background: #ffedde!important;
  color: #f2711c!important;
}

/* Lifted from semantic-ui-react to mimic that library's look and feel*/
.input-orange-semantic-ui-container > * {
  border-color: #f2ae1c!important;
  background: #ffedde!important;
  color: #f2711c!important;
}

/**
 * We use the <pre> tag to display the HTTP error response body when a form
 * submit fails for an unknown reason. But content in <pre> doesn't
 * automatically wrap, so a really long line  will break out of all its
 * containers and keep going off the right side of the window. This prevents
 * that. https://stackoverflow.com/a/248013
 */
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

/**
 * All the <Modal> components used by this app have dimmed backgrounds. If ever
 * we use a <Modal dimmed="inverted"> ("inverted" means the background is not
 * dimmed), this CSS will ensure that it behaves exactly as a non-inverted
 * <Modal> does, with the background dimmed. So then why do we ever use an
 * inverted <Modal>? Because Semantic UI has a bug in which <Loader>s are
 * invisible in <Modal>s; see CODE_COMMENTS_72 for details.
 */
 .dimmer.inverted {
     background-color: rgba(0, 0, 0, 0.8) !important;
 }


/**
 * Add this class to a <Grid> component to create 0 space between grid rows
 */
.tight-grid-rows .row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/**
 * Add this class to a <Grid> component to create 0 space between grid rows
 */
.semitight-grid-rows .row {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

/**
 * When a grid is in a form, CSS specificity overrides our tight-grid-rows class
 * on a <Grid> element with Semantic UI's default 0.5em top and bottom padding
 * on "form .grid .row". So put this class onto Semantic UI's <Form> element and
 * it will be even more specific than the default. (Why 0.1em instead of 0?
 * Because form inputs have 1px bounding boxes that don't look good when butted
 * against each other.)
 */
 .tight-grid-rows-form .grid .row {
  padding-top: 0.1em !important;
  padding-bottom: 0.1em !important;
}

/**
 * Add this class to a <Grid> component to create 0 space between grid columns
 */
 .tight-grid-columns .column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/**
 * Add this class to a <Grid> component to create 0 space between grid
 */
.semitight-grid-columns .column {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}


/**
 * There's a bug in Semantic UI React where Buttons that route you to a
 * different page using React Router-- e.g.
 *
 * import { Button } from 'semantic-ui-react'
 * import { Link } from 'react-router-dom'
 * ...
 * <Button
 *    as={Link}
 *    to={'/some/path'}
 * />
 *
 * --have messed-up styling in Safari; the buttons have a completely white
 * overlay that makes it impossible to read their text
 * (https://microstartap3.atlassian.net/browse/TP3-1113). The problem is that
 * such components are rendered as <a> tags with a `type="button"` attribute
 * rather than as <button> tags, and these <a> tags with `type="button"`
 * attribute get a "-webkit-appearance: none;" style applied to them. This is
 * done intentionally by Semantic UI React in order to prevent two bugs,
 * detailed at /node_modules/semantic-ui-css/semantic.css:
 *
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 *
 * Unfortunately, the cure is worse than the disease, so we override the CSS
 * here and make it as if this built-in Semantic UI fix never existed.
 *
 * Incidentally, this also fixes a tiny UI bug in Chrome where Buttons that
 * route you to a different page using React Router have a 1px black border
 * around them, ruining the rounded effect that Semantic UI gives to all buttons
 * by default.
 */
 button,
 html [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: none;
   /* 2 */
 }


 /**
 * Semantic UI <Container> components have a bordered white background by
 * default; use this class to unset.
 */
.container-no-background {
  /* set background color to transparent: https://stackoverflow.com/a/18837025 */
  background-color:rgba(0, 0, 0, 0.0)!important;
  border: none!important;
}
 /**
 * Semantic UI <Container> components have a bottom margin of 10px by
 * default; use this class to unset.
 */
.container-no-margin {
  margin-bottom: 0px!important;
}


 /**
 * Every textarea throughout the app should only be a max of 250px wide
 */
textarea {
  max-width: 250px;
}

 /**
 * CODE_COMMENTS_273: change the red "Required" asterisk of a form label to
 * never take up its own line of text.
 */
 .ui.form .required.field>label:after, .ui.form .required.fields.grouped>label:after, .ui.form .required.fields:not(.grouped)>.field>label:after {
   display: inline;
 }


/* CODE_COMMENTS_275: hides spinner arrows on <input type="number"> */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


/* Sometimes you want to hide or display things based on screen width */
@media screen and (max-width: 639px) {
  .undisplayed-in-phone-portrait-view {
    display: none!important;
  }
}
/* Sometimes you want to hide or display things based on screen width */
@media screen and (min-width: 640px) {
  .displayed-only-in-phone-portrait-view {
    display: none!important;
  }
}
@media screen and (max-width: 991px) {
  .undisplayed-in-tablet-portrait-and-all-phone-views {
    display: none!important;
  }
}

/* CODE_COMMENTS_276 */
.two-column-grid-1st-column-width-fit-content {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  /* Center content vertically */
  align-items: center;
}

/* e.g. Reported Inventory vs Calculated Inventory */
.very-compact-table thead th, .very-compact-table td {
  padding: 0.1em 0.5em!important;
}


 /*
  * *****************************************************************************
  * History Tables
  * *****************************************************************************
 */

.history-table-totals-row th {
  font-weight: bold!important;
}

/*
 * *****************************************************************************
 * Page Sections
 * *****************************************************************************
*/

.segment-header-bottom-margin {
  /* When a form is at the top of a <Section>, it crowds the section's
  heading. */
  margin-bottom: 1.5em!important;
}


.compact-grid-row {
  padding-top: 0.25rem !important; /* 1rem by default */
  padding-bottom: 0.25rem !important; /* 1rem by default */
}

/*
 * *****************************************************************************
 * Form section in labeled grid layout
 * *****************************************************************************
*/

/* Semantic-UI's default right margin from label to field is 10px, not quite
enough. */
.field.inline label {
  margin-right: 20px!important;
}

/*
 * *****************************************************************************
 * Form separated into sections
 * *****************************************************************************
*/

.form-sections .segment {
  margin-bottom: 15px!important;
}


/*
 * *****************************************************************************
 * "Vertical" form sections (with horizontal "themeatic break" lines at the
 * top and bottom of the section), e.g. the Report Inventory form
 * *****************************************************************************
*/

form .segment.vertical h3 {
  padding-bottom: 15px!important;
}

form .segment.vertical {
  padding-bottom: 2em;
}


/*
 * *****************************************************************************
 * Form with inline labels
 * *****************************************************************************
*/

/* by default, form labels have an annoying bottom margin that makes it so that
they're not visually vertically centered even when the semantic-ui
'verticalAlign' prop of the form field is set to "middle" */
form .grid label {
  margin: 0!important;
}

/* padding between grid rows is 1rem top and bottom by default, which is a
little too much */
form .grid .row {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}

/* Due to CODE_COMMENTS_97, Semantic UI <Grid>s have a default
margin (all directions) of -1rem, which means that the submit buttons of forms
that use grids float up into and overlap the last field. This fixes that. */
form .grid {
  margin-bottom: 1rem!important;
}

/* Acknowledge Inbound Shipments redesign start*/
#acknowledgeInboundShipmentsSection #ack-inbs-source div {
  height: auto;
}
#acknowledgeInboundShipmentsSection #group-by-rows .toplabel {
  /*align-items: flex-start !important;*/
  /*padding-top: 10px !important;*/
  font-weight: bolder;
}
#acknowledgeInboundShipmentsSection #group-by-rows {
  /*padding-bottom: 20px;*/
  display: flex;
  flex-direction: row;
  width: auto;
}
#acknowledgeInboundShipmentsSection .fields {
  flex-wrap: wrap;
  /*flex: 1 1 80%;*/
  row-gap: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #767676;
  /* Set to 100% by Tad on 12/19/2022 so that Tad could display Order ID as a
  text-only field to the Ack Inbound Shipments form for Kegstar International
  customers. This was previously set to 95% by Sunil, but Tad needed more
  horizontal room for the Order ID field, and setting to 100% did the trick. Tad
  tested several customer types which don't render the Order ID field and didn't
  see any difference between 95% and 100%.
  */
  max-width: 100%;
}

#acknowledgeInboundShipmentsSection .fields>.field .field-label {
  text-decoration: underline;
}

#acknowledgeInboundShipmentsSection #group-by-rows .field {
  flex-direction: row !important;
  width: auto !important;
  padding: 4px 0;
  min-height: 46px;
}

#acknowledgeInboundShipmentsSection #group-by-rows .field .ui.input{
  max-width: 150px;
}

#acknowledgeInboundShipmentsSection #group-by-rows [class*="compact-date-field"]{
  max-width: 150px;
}

#acknowledgeInboundShipmentsSection .fields .wide.field {
  justify-content: flex-start !important;
  margin-left: 5em;
}
/* Acknowledge Inbound Shipments redesign end*/

/* Report/edit outbound shipments & self-collection shipments start */
#ReportOutboundShipmentsSection #group-by-rows .toplabel,
#outboundShipmentsToEverywhereExceptPubsAndSelfDistribution #group-by-rows .toplabel,
#ReportSelf-CollectionforReuseSection #group-by-rows .toplabel,
#selfCollectionShipments #group-by-rows .toplabel {
  align-items: flex-start !important;
  padding-top: 10px !important;
  font-weight: bolder;
}
#ReportOutboundShipmentsSection #group-by-rows,
#outboundShipmentsToEverywhereExceptPubsAndSelfDistribution #group-by-rows,
#ReportSelf-CollectionforReuseSection #group-by-rows,
#selfCollectionShipments #group-by-rows {
  /*padding-bottom: 20px;*/
  display: flex;
  flex-direction: row;
  width: auto;
}
#ReportOutboundShipmentsSection .fields,
#outboundShipmentsToEverywhereExceptPubsAndSelfDistribution .fields,
#ReportSelf-CollectionforReuseSection .fields,
#selfCollectionShipments .fields {
  flex-wrap: wrap;
  flex: 1 1 80%;
  row-gap: 20px;
  padding-bottom: 10px;
}

#ReportOutboundShipmentsSection #group-by-rows .field,
#outboundShipmentsToEverywhereExceptPubsAndSelfDistribution #group-by-rows .field,
#ReportSelf-CollectionforReuseSection #group-by-rows .field,
#selfCollectionShipments #group-by-rows .field {
  flex-direction: row !important;
  width: auto !important;
  padding: 4px 0;
  min-height: 46px;
}

#ReportOutboundShipmentsSection #group-by-rows .field .ui.input,
#outboundShipmentsToEverywhereExceptPubsAndSelfDistribution #group-by-rows .field .ui.input,
#ReportSelf-CollectionforReuseSection #group-by-rows .field .ui.input,
#selfCollectionShipments #group-by-rows .field .ui.input {
  max-width: 150px;
}

#ReportOutboundShipmentsSection .fields .wide.field,
#outboundShipmentsToEverywhereExceptPubsAndSelfDistribution .fields .wide.field,
#ReportSelf-CollectionforReuseSection .fields .wide.field,
#selfCollectionShipments .fields .wide.field {
  justify-content: flex-start !important;
}
/* Report/edit outbound shipments & self-collection shipments ends */
/* Report Buyback shipment redesign start */
#ReportBuybackShipmentsSection #group-by-rows .toplabel {
  align-items: flex-start !important;
  padding-top: 10px !important;
  font-weight: bolder;
}
#ReportBuybackShipmentsSection #group-by-rows {
  /*padding-bottom: 20px;*/
  display: flex;
  flex-direction: row;
  width: auto;
}
#ReportBuybackShipmentsSection .fields {
  flex-wrap: wrap;
  flex: 1 1 80%;
  row-gap: 20px;
  padding-bottom: 10px;
}

#ReportBuybackShipmentsSection #group-by-rows .field {
  flex-direction: row !important;
  width: auto !important;
  padding: 4px 0;
  min-height: 46px;
}

#ReportBuybackShipmentsSection #group-by-rows .field .ui.input{
  max-width: 150px;
}

#ReportBuybackShipmentsSection .fields .wide.field {
  justify-content: flex-start !important;
}
/* Report Buyback shipment redesign end */
/* Report self distribution shipment redesign start */
#ReportSelf-DistributionandOwnPubsShipmentsSection #group-by-rows .toplabel {
  align-items: flex-start !important;
  padding-top: 10px !important;
  font-weight: bolder;
}
#ReportSelf-DistributionandOwnPubsShipmentsSection #group-by-rows {
  /*padding-bottom: 20px;*/
  display: flex;
  flex-direction: row;
  width: auto;
}
#ReportSelf-DistributionandOwnPubsShipmentsSection .fields {
  flex-wrap: wrap;
  flex: 1 1 80%;
  row-gap: 20px;
  padding-bottom: 10px;
}

#ReportSelf-DistributionandOwnPubsShipmentsSection #group-by-rows .field {
  flex-direction: row !important;
  width: auto !important;
  padding: 4px 0;
  min-height: 46px;
}

#ReportSelf-DistributionandOwnPubsShipmentsSection #group-by-rows .field .ui.input{
  max-width: 150px;
}

#ReportSelf-DistributionandOwnPubsShipmentsSection .fields .wide.field {
  justify-content: flex-start !important;
}
/* Report self distribution shipment redesign end */
/* Report kegs fill redesign start*/
#ReportKegFillsSection #group-by-rows .toplabel {
  align-items: flex-start !important;
  padding-top: 10px !important;
  font-weight: bolder;
}
#ReportKegFillsSection #group-by-rows {
  /*padding-bottom: 20px;*/
  display: flex;
  flex-direction: row;
  width: auto;
}
#ReportKegFillsSection .fields {
  flex-wrap: wrap;
  flex: 1 1 80%;
  row-gap: 20px;
  padding-bottom: 10px;
}

#ReportKegFillsSection #group-by-rows .field {
  flex-direction: row !important;
  width: auto !important;
  padding: 4px 0;
  min-height: 46px;
}

#ReportKegFillsSection #group-by-rows .field .ui.input{
  max-width: 150px;
}

#ReportKegFillsSection .fields .wide.field {
  justify-content: flex-start !important;
}
/* Report kegs fill redesign end*/
/* Edit kegs fill redesign start*/
#kegFills #group-by-rows .toplabel {
  align-items: flex-start !important;
  padding-top: 10px !important;
  font-weight: bolder;
}
#kegFills #group-by-rows {
  /*padding-bottom: 20px;*/
  display: flex;
  flex-direction: row;
  width: auto;
}
#kegFills .fields {
  flex-wrap: wrap;
  flex: 1 1 80%;
  row-gap: 20px;
  padding-bottom: 10px;
}

#kegFills #group-by-rows .field {
  flex-direction: row !important;
  width: auto !important;
  padding: 4px 0;
  min-height: 46px;
}

#kegFills #group-by-rows .field .ui.input{
  max-width: 150px;
}

#kegFills .fields .wide.field {
  justify-content: flex-start !important;
}
/* Edit kegs fill redesign end*/

.width70 {
  max-width: 70% !important;
}
.border-1 {
  border: 1px solid #a39e9e;
}

.pad20 {
  padding: 20px !important;
}
.noSidePad {
  padding-left:  0px !important;
  padding-right:  0px !important;
}
.onlySidePad {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.border-radius5 {
  border-radius: 5px;
}

.row.compact-grid-row.border-1.border-radius5.pad20 {
  padding: 20px !important;
}

.disable-weekend-dates .react-datepicker__day--weekend {
  cursor: default;
  color: #ccc;
  background-color: transparent;
}
.disable-weekend-dates .react-datepicker__day--weekend:hover {
  background-color: transparent;
}
.max-width-100px {
  max-width: 100px;
  word-wrap: break-word;
  white-space: break-spaces !important;
}

.hidden {
  display: none;
}

iframe {
  border: 0px !important;
  width: 100%;
  height: 95%;
}
