@media screen and (max-width: 768px) {
  #navbar a, .item {
    font-size: 0.9rem!important;
  }
  #navbar a, .item {
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
  }
  #navbar a#navbarHomeLinkLogo {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  /* Little smaller than the wider view */
  #navbarHomeLinkLogo img {
    height: 16px!important;
    width: auto;
  }
}

/* 
 * What if the "Hi, Name @ Brewery" text is really long (e.g. long customer
 * name)? By default, the menu overflows its parent container, which is bad.
 * These styles ensure no overflow happens and that any additional text is
 * simply cut off, which is the best we can do. (Unfortunately I haven't figured
 * out how to use "text-overflow: ellipsis;" here because a "display" and
 * "width" attribute would have to be set on this element
 * [https://stackoverflow.com/a/7993098] and I can't figure out how to set those
 * attributes to valid values without messing things up.)
 */
#navbar #customizedGreeting {
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
}

/*
 * Makes the flex: 0 1 auto; rule above work as it's supposed to:
 * https://stackoverflow.com/a/31972181
 */
#navbar .right {
  min-width: 0;
}

/*
 * The <Image> element in the .js file has no 'size' prop, we had to unset it
 * because the images size would change depending on how long customizedGreeting
 * is (for reasons I don't completely understand). Therefore, we need to set a
 * hard height here. Also, only px works to set the height: setting it to em
 * also makes the image image change size depending on how long
 * customizedGreeting is.
 */
#navbarHomeLinkLogo img {
  height: 20px;
  width: auto;
}

/*
 * Makes it so that the logo width doesn't get squished when customizedGreeting
 * is really long.
 */
#navbarHomeLinkLogo {
  flex: 0 0 auto;
}