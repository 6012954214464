/* The default styling of react-phone-number-input isn't so great. Fortunately,
   it's easy to improve it with these styles.
 */
.react-phone-number-input input {
  /* The goal here is to match the styling Semantic UI React applies to an
  <input> field, that way if the <PhoneNumber> field has an extension field to
  the right of it (the extension field is an <input> field), they'll match
  visually. */
  padding: 1.27em 1em!important;
}

.react-phone-number-input__icon {
  border: 0!important;
  height: 1.2em!important;
}
