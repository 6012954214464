/* Grey background on selected and hovered vids */
.video-playlist-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.video-playlist-item-selected {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Larger than mobile & tablet */
@media only screen and (min-width: 992px) {
  /* Decrease the space between the video and the playlist */
  .video-playlist-grid .column:nth-child(1) {
    padding-right: 0 !important;
  }
  .video-playlist-grid .column:nth-child(2) {
    padding-left: 0 !important;
  }
}

/* Make the playlist height match the video height (ugly hack, I know) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .playlist-section { overflow: auto; max-height: 350px; }
}
@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .playlist-section { overflow: auto; max-height: 425px; }
}
@media only screen and (min-width: 1280px) {
  .playlist-section { overflow: auto; max-height: 475px; }
}
