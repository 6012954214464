/**
 * These next two rules make it so that there's very little space between an
 * input field and its error message.
 */
.public-page-container .field-with-error-message {
  margin-bottom: 1em !important;
}
.public-page-container .field-with-error-message .field {
  margin-bottom: 0em !important;
}

/**
 * error messages
 */
.field-with-error-message small {
  color: #9F3A38;
}

.public-page-container .small-link {
  margin-top: 1em;
  font-size: 0.9em;
  /* margin won't stick without this */
  display: block;
}

.public-page-container h1, h2, h3, h4, h5, h6 {
  margin-top: 0.4em !important;
}
