.buorg {
    position: absolute;
    /* position: fixed; */
    z-index: 111111;
    width: 100%;
    top: 0px;
    left: 0px;
    border-bottom: 1px solid #A29330;
    text-align: center;
    color: #000;
    background-color: #fff8ea;
    font: 18px Calibri, Helvetica, sans-serif;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /* animation: 1s ease-out 0s buorgfly */
}

.buorg-pad {
    padding: 9px;
    line-height: 1.7em;
}

.buorg-buttons {
    display: block;
    text-align: center;
}

#buorgig, #buorgul, #buorgpermanent {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    padding: 1px 10px;
    border-radius: 4px;
    font-weight: normal;
    background: #5ab400;
    white-space: nowrap;
    margin: 0 2px;
    display: inline-block;
}

#buorgig {
    background-color: #edbc68;
}

@media only screen and (max-width: 700px) {
    .buorg div {
        padding: 5px 12px 5px 9px;
        line-height: 1.3em;
    }
}

/*
@keyframes buorgfly {
    from {
        opacity: 0;
        transform: translateY(-30px)
    }
    to {
        opacity: 1;
        transform: translateY(0px)
    }
}
*/

.buorg-fadeout {
    /* transition: visibility 0s 3s, opacity 3s ease-out 1s; */
}

.buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
    display: inline-block;
    background: no-repeat 0px center;
}
