.horizontal-form-section-as-labeled-grid .row .column:not(:first-child)  {
  /* Give the input field columns a max width so they don't stretch the entire
  length of wide screens*/
  max-width: 125px;
}

/* Decrease the padding between input field columns on narrow screens. */
@media (max-width: 767px) { /* phone landscape max width */
  .horizontal-form-section-as-labeled-grid .row .column:not(:first-child)  {
    /* decrease right & left padding input field columns from the default 1rem
    to 0.1rem. */
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important;
  }

  /* The decrease of padding in the previous lines has the effect that the left
  edge of the first column and right edge of the last column now don't line up
  with the rest of the form. These margin increases fix that. */
  .horizontal-form-section-as-labeled-grid .row .column:first-child  {
    margin-left: 0.9rem;
  }
  .horizontal-form-section-as-labeled-grid .row .column:last-child  {
    margin-right: 0.9rem;
  }

  /* Fitting five digits into the viewable area of an input that has 3 field
  columns (a common requirement for the web app, e.g. keg container quantity
  fields in the Report Inventory form) is only possible on mobile portrait by
  decreasing the inner padding of the input fields. NOTE: This rule may need to
  be extended to other types of fields in the future, such as textArea.
  Unfortunately, I can't think of a better way to apply these styles than
  targetting specific elements, because form fields can be nested within a grid
  row/column in arbitrary ways (for example, imagine a date field that, for
  reasons known only to the developers of the 3rd-party date field library we're
  using, gets wrapped in three container divs). */
  .horizontal-form-section-as-labeled-grid input {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
  }
}