/* These styles are what makes the link stay at the bottom-right-corner.
Inspiration from https://react.semantic-ui.com/layouts/sticky */

.hidden-content {
  position: fixed;
  bottom: 0px;
  /* Why -4px? Because when I drag the mouse to the very bottom right of the
  screen, I want the icon to be clickable--not 2px away from the very bottom
  right, not 1px away, _very bottom right_. Without this negative margin, the
  icon isn't quite clickable, at least not in Chrome */
  right: -4px;
  zIndex: 10;
  opacity: 0;
}

.hidden-content:hover {
  opacity: 1;
}
