.narrower-layout {
  margin: auto;
  width: 50%;
  padding-top: 12px;
}

@media screen and (max-width: 500px) {
  .narrower-layout {
    width: 100%; } }

@media screen and (max-width: 768px) and (min-width: 500px) {
  .narrower-layout {
    width: 70%; } }

@media screen and (max-width: 1440px) and (min-width: 960px) {
  .narrower-layout {
    width: 40%; } }

@media screen and (min-width: 1440px) {
  .narrower-layout {
    width: 30%; } }
