.two-column-splash-screen-container {
  /* This overrides our custom-set site-wide container styles */
  background-color: transparent !important;
  border: none !important;

  margin-top: 50px;
}

.two-column-splash-screen-container p {
  font-size: 1.125em;
}

.two-column-splalogo {
  font-size: 30em !important;
  color: #cccccc;
}

.grey-logo-fill {
  fill: #cccccc !important;
}
