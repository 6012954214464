/* A little breather space above all headings except the first */
.reported-vs-calc-inv-container div:not(:first-child) h3 {
  margin-top: 15px!important;
}


/* 
 * https://stackoverflow.com/a/38948646: Center the heading with button to the
 * right of it
 */
#centered-first-child-right-second-child {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

#first-child-of-centered-first-child-right-second-child { grid-column-start: 2; }
#second-child-of-centered-first-child-right-second-child { margin-left: auto; }