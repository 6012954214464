.form-as-table .fields {
  /* space between rows */
  margin-bottom: 0.4em!important;
}

.form-as-table .fields .field:not(:first-child) {
  /* space between fields */
  padding-left: 0.1em!important;
}

.form-as-table .fields .field:not(:last-child) {
  /* space between fields */
  padding-right: 0.1em!important;
}

/*
vertically centers fields within their row
 */
.form-as-table .fields:not(:first-child) .field {
  display: flex!important;
  flex-direction: row!important;
  justify-content: center!important;
  /* buttons will stretch without the align-items prop:
  stackoverflow.com/q/33887051 */
  align-items: center!important;
}

/*
When a form is formatted as a table, we want labels at the top for each column.
What happens when some labels are short and one line long, but others are
longer, taking up multiple lines? What we want is for the labels to be at the
top of the "label box" and the first field row to be pushed down so that all
fields in the first table row are horizontally aligned.
 */
.form-as-table .fields:first-child .field {
  display: flex!important;
  flex-direction: column!important;
  justify-content: space-between!important;
  /*
  setting align-items to center assumes that all forms formatted as a table will
  want both their labels and their fields centered within a column. This
  assumption is true for all table-forms in the original site design, but it may
  not be flexible enough for future future iterations. Use with care.
   */
  align-items: center!important;
}

/*
Some padding for the labels
 */
.form-as-table .fields:first-child label {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

/*
If the first column is for "delete row" buttons, We want only a tiny amount of
space between the button and the field to the right of the button
 */
.remove-row-button {
  margin-right: 0!important;
}

/*
In a dropdown with a fixed width, if the text of an option is longer than the
dropdown width, Semantic UI React will increase the height of the dropdown once
selected to display the entire text. But in our forms formatted as tables, we
want to keep the height of the dropdown constant and display only part of the
text (with an ellipsis at the end).
 */
.form-as-table .dropdown {
  width: 100%;
}
.form-as-table .dropdown div.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

/*
This is a dirty, awful, sickening hack, but it's the only way to vertically
center a checkbox in the first row of a form-formatted table. There's no way
around giving the checkbox some height.
 */
.form-as-table .fields .field .checkbox {
  height: 38px;
  padding-top: 11px;
}

/**
 * Again, a dirty, awful, sickening hack, but it must be done.
 */
.form-as-table .fields .field .vertically-centered-field,
.form-as-table .text-in-lieu-of-form-field {
  height: 38px;
  display: flex!important;
  flex-direction: column!important;
  justify-content: center!important;
  /* if a string is longer than one line, break on separate words if possible,
  otherwise break a really long word at arbitrary characters so it doesn't
  extend beyone the line. */
  word-break: break-word!important;
  /* Text is already centered within form fields; however, if the text requires
  multiple lines, the lines after the first won't be centered without this css
  rule (they'll be left aligned) */
  text-align: center;
}

/*
Reduces the left and right spacing between the field border and the field input
text, allowing us to style a more compact-looking form-as-table
 */
.form-as-table .fields input {
  padding-left: .5em!important;
  padding-right: .5em!important;
}

.form-as-table .fields .field div.dropdown {
  /* For some silly reason, Semantic UI React's default-sized <Select> component
  is 4 pixels taller than its default-sized <Input> component. This padding
  fixes that. */
  padding-top: .8em;
  padding-bottom: .6em;
  /* Reduces the left spacing between the field border and the selection text,
  allowing us to style a more compact-looking form-as-table (we don't mess with
  the right spacing because that's where the selection arrow is) */
  padding-left: .5em
}


/*
For use on disabled <Form> fields you don't want them to look any lighter than
when not disabled
 */
.form-as-table .fields .disabled,
.form-as-table .fields label {
  opacity: unset!important;
}

/* IE loses its mind in form-as-table components. This custom CSS that targets
/* only IE revives some sanity. */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-as-table input {
    width: 100%!important;
  }
  .form-as-table label {
    width: inherit!important;
  }
  .form-as-table .text-in-lieu-of-form-field {
    width: inherit!important;
  }
  /* Our 'width: 100%!important;' style above tromps over this one, so we
  /* redeclare it here */
  .form-as-table .compact-date-field {
    width: 8em!important;
  }
  .form-as-table .field .react-datepicker-wrapper input {
    margin-bottom: -0.3rem!important;
  }
}
