.bold-text-slightly-larger {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: left;
}

.bold-text-input-field input {
  font-weight: bold;
  font-size: 1.1rem;
}

.custom-divider {
  margin: 0.2rem!important;
  text-align: center;
  font-weight: bold;
  color: #9c9c9c;
}
