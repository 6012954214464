/*Sticky footer styles from
https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/.
Unfortunately, I can't get this to work for IE11. However, the lack of a
sticky footer for our small percentage of users on IE11 isn't a big deal; the
footer still renders at the bottom of the content on IE11 (it doesn't overlap
other content or anything buggy like that).*/

.universal-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.universal-layout-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 100%;
}

.universal-layout-content:after {
  content: "\00a0";
  display: block;
  /* The Public Layout wrapper has a padding of 1em (a default of Semantic UI
  React), so the privacy policy in the footer won't be clickable unless we set a
  margin-top to at least that same amount (this scenario only happens when the
  window is very short, e.g. on a phone, rendering a scrollbar on public pages).
  */
  margin-top: 1.5em;
  height: 0;
  visibility: hidden;
}


.universal-layout-footer,.universal-layout-header {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  /* a little space below the very bottom of the window */
  margin-bottom: 0.5em;
}
